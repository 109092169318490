    import React from "react";
    import axios from "axios";
    import Box from '@mui/material/Box';
    import Button from '@mui/material/Button';
    import BottomNavigation from '@mui/material/BottomNavigation';
    import Paper from '@mui/material/Paper';
    import Grid from '@mui/material/Grid';
    import { styled } from '@mui/material/styles';
    import { useLocation } from "react-router-dom";
    import { useNavigate } from "react-router-dom";

    import TopLogo from '../../components/layout/TopLogo';
    import SpectraNearYouCard from "../../components/card/SpectraNearYouCard";
    import SpectraNearYouDetail from "../../components/card/SpectraNearYouDetail";
    import PrimaryButton from "../../components/button/PrimaryButton";

    const BackButton = styled(Button, {
        shouldForwardProp: (prop) => prop !== "showLabel"
    })({
        fontFamily: 'inherit',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '24px',
        height: '48px',
        borderRadius: '28px',
        backgroundColor: 'white',
        color: '#0A4197',
        minWidth: '100px',
        gap: '10px',
        justifyContent: 'center',
        alignItems: 'center',
        flex: '1',
        display: 'flex',
        padding: '15px',
    })

    const RouteButton = styled(Button, {
        shouldForwardProp: (prop) => prop !== "showLabel"
    })({
        fontFamily: 'inherit',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '15px',
        height: '70%',
        borderRadius: '28px',
        backgroundColor: '#0A4197',
        color: 'white',
        minWidth: '100px',
        gap: '10px',
        justifyContent: 'center',
        alignItems: 'center',
        flex: '1',
        display: 'flex',
        padding: '15px',
    })

    export default function SpectraNearYouList() {
        const latitude = useLocation()?.state?.latitude;
        const longitude = useLocation()?.state?.longitude;
        const type = useLocation()?.state?.type;

        const [open, setOpen] = React.useState(true);
        const [currentProps, setCurrentProps] = React.useState({});

        const pull_data = (data) => {
            console.log('data received', data);
            setOpen(data);
        }

        const get_current_props = (data) => {
            console.log('props received', data);
            setCurrentProps(data);
        }

        const navigate = useNavigate();

        const goBack = () =>
            navigate('/spectra-near-you-main');

        const [clinics, setClinics] = React.useState([]);

        React.useEffect(() => {
            const baseUrl = process.env.REACT_APP_BASE_URL || 'https://api-ngs.scapp.work'
            const fetch = async () => {
                try {
                    const { data } = await axios.get(baseUrl + '/searchSpectraNearYou', {
                        params: {
                            latitude: latitude,
                            longitude: longitude,
                            type: type
                        }
                    });
                    setClinics(data);
                } catch (err) {
                    console.error(err);
                }
            };
            fetch();

            
        }, []);

        return (
            <React.Fragment>
                {open ? (
                    <div>
                        <TopLogo />
                        <div
                            style={{
                                background:
                                    "linear-gradient(152.04deg, rgb(10, 65, 151) -0.97%, rgb(32, 159, 237) 81.57%)",
                                minHeight: "calc(100vh - 60px)",
                                padding: "20px",
                            }}
                        >
                            <div className="text-white text-center text-[35px] mt-[20px]">
                                <p>ค้นหาโรงพยาบาลสัตว์ คลินิก<br />และเพ็ทช็อป ใกล้ฉัน</p>
                            </div>
                            <div className="text-white text-left text-[25px] mt-[20px] fontWeight: 1000">
                                <p>โรงพยาบาลสัตว์ คลินิกที่ใกล้ฉัน</p>
                            </div>
                            {clinics && clinics.body && clinics.body.length > 0 &&
                                <div>
                                    {clinics.body.map(clinic => (
                                        <SpectraNearYouCard
                                            key={clinic.clinicPetShopId}
                                            shopName={clinic.shopName}
                                            shopNo={clinic.shopNo}
                                            distance={clinic.distance}
                                            latitude={clinic.latitude}
                                            longitude={clinic.longitude}
                                            facebook={clinic.facebook}
                                            line={clinic.line}
                                            mobileNumber={clinic.mobileNumber}
                                            openTime={clinic.openTime}
                                            closeTime={clinic.closeTime}
                                            type={clinic.type}
                                            clinicPetShopId={clinic.clinicPetShopId}
                                            func={pull_data}
                                            funz={get_current_props}
                                        />
                                    ))}
                                </div>
                            }
                            <Grid container spacing={1}>
                                <Grid item xs={12} display="flex" justifyContent="center"
                                    alignItems="center" alignContent="center">
                                    <PrimaryButton
                                        variant="contained"
                                        style={{
                                            minWidth: "50px",
                                            background: "#197FC5",
                                            color: "white",
                                            width: "90%",
                                        }}
                                        href="https://www.spectrafordog.com/TH/clinic.html"
                                        target="_blank"
                                    >
                                        ดูทั้งหมด
                                    </PrimaryButton>
                                </Grid>
                            </Grid>

                            <div style={{ marginTop: 80 }}>
                                <Box>
                                    <Paper sx={{
                                        position: 'fixed', bottom: 0, left: 0, right: 0
                                    }} elevation={1}>
                                        <BottomNavigation
                                            style={{
                                                alignItems: 'center', height: '90px'
                                            }}
                                        >
                                            <BackButton
                                                variant="outlined"
                                                style={{
                                                    minWidth: "90px",
                                                    margin: "0px 15px",
                                                }}
                                                onClick={goBack}
                                            >
                                                ย้อนกลับ
                                            </BackButton>
                                        </BottomNavigation>
                                    </Paper>
                                </Box>
                            </div>
                        </div>
                    </div>
                ) : (<div>
                    {/* {clinics && clinics.body && clinics.body.length > 0 &&
                        <div>
                            {clinics.body.slice(0, 1).map(clinic => (
                                <SpectraNearYouDetail
                                    key={clinic.clinicPetShopId}
                                    shopName={clinic.shopName}
                                    distance={clinic.distance}
                                    latitude={clinic.latitude}
                                    longitude={clinic.longitude}
                                    facebook={clinic.facebook}
                                    line={clinic.line}
                                    mobileNumber={clinic.mobileNumber}
                                    openTime={clinic.openTime}
                                    closeTime={clinic.closeTime}
                                    type={clinic.type}
                                    func={pull_data}
                                />
                            ))}
                        </div>
                    } */}
                    <SpectraNearYouDetail func={pull_data} funz={currentProps} />
                </div>
                )
                }
            </React.Fragment >
        )
    }