import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import PrimaryButton from "../../components/button/PrimaryButton";
import TopLogo from "../../components/layout/TopLogo";
import { CreateUsePoint, SearchFreebiesById } from "../../services/RedeemGift";
import SecondaryButton from "../../components/button/SecondaryButton";
import RedeemGiftError from "./RedeemGiftError";
import RedeemGiftSuccess from "./RedeemGiftSuccess";
import dayjs from "dayjs";

export default function RedeemGiftDetail() {
  const navigate = useNavigate();
  let { freebiesId, clinicPetShopId } = useParams();
  const [detail, setDetail] = React.useState({});
  const [canRedeem, setCanRedeem] = React.useState(false);
  const [isEmpty, setIsEmpty] = React.useState(false);
  const [usePointError, setUsePointError] = React.useState(false);
  const [successData, setSuccessData] = React.useState(null);

  useEffect(() => {
    (async () => {
      try {
        const detail = await getSearchFreebiesById();
        console.log("detail", detail);
        setDetail(detail);
      } catch (error) {
        console.log("fetchData error", error);
      }
    })();
  }, []);

  async function getSearchFreebiesById() {
    const { data } = await SearchFreebiesById({
      params: { freebiesId, clinicPetShopId },
    });
    if (+data["resultCode"] === 20000) {
      setCanRedeem(true);
      return data.body || {};
    } else if (+data["resultCode"] === 40304) {
      setCanRedeem(false);
      return data.body || {};
    } 
    else if (+data["resultCode"] === 40301) {
      setCanRedeem(false);
      setIsEmpty(true)
      return data.body || {};
    } 
    else {
      setCanRedeem(false);
      return {};
    }
  }

  async function postCreateUsePoint() {
    const { data } = await CreateUsePoint({
      data: {
        freebiesId: freebiesId,
        clinicPetShopId: clinicPetShopId,
        pickUpType: "DELIVERY",
      },
    });
    if (+data["resultCode"] === 20000) {
      setUsePointError(false);
      const body = data["body"] || {};
      // set address
      let address = "-";
      const clinicPetShop = body["clinicPetShop"] || {};
      const deAddressDetail = clinicPetShop["deAddressDetail"] || "";
      const province = clinicPetShop["deAddress"]["province"] || "";
      const amphoe = clinicPetShop["deAddress"]["amphoe"] || "";
      const subDistrict = clinicPetShop["deAddress"]["subDistrict"] || "";
      const zipcode = `${clinicPetShop["deAddress"]["zipcode"] || ""}`;
      address = `${deAddressDetail} ${subDistrict} ${amphoe} ${province} ${zipcode}`;
      // set success data
      setSuccessData({
        urlPicture: detail["urlPicture"],
        name: detail["name"],
        createdDate: dayjs(body["createdDate"]).format("DD/MM/YYYY HH:mm:ss"),
        points: detail["points"]?.toLocaleString(),
        address,
      });
    } else {
      setUsePointError(true);
      setSuccessData(null);
    }
  }

  function onClickBackFromError() {
    setUsePointError(false);
    setSuccessData(null);
  }

  return (
    <>
      <TopLogo />
      {!usePointError && !successData && (
        <div
          style={{
            background:
              "linear-gradient(152.04deg, rgb(10, 65, 151) -0.97%, rgb(32, 159, 237) 81.57%)",
            minHeight: "calc(100vh - 60px)",
            padding: "20px",
          }}
        >
          <div
            className="flex flex-col justify-between bg-white px-5 py-10 rounded-[5px]"
            style={{ minHeight: "calc(100vh - 100px)" }}
          >
            <div className="text-center leading-8">
              <div className="text-[18px] text-red font-[600]">
                สิทธิ์การแลกรางวัล {detail["qty"]?.toLocaleString()} สิทธิ์
              </div>
              <div className="text-[36px] text-lightBlue">
                ยืนยันการแลกของรางวัล
              </div>
              <div className="text-[24px]">{detail["name"]}</div>
            </div>
            <div className="text-center">
              <img
                src={detail["urlPicture"]}
                alt="redeem image"
                className="w-1/2 inline-block"
              />
            </div>
            <div>
              <div className="text-[24px] text-lightBlue">
                เงื่อนไขการรับของรางวัล
              </div>
              <div
                className="pl-4"
                dangerouslySetInnerHTML={{ __html: detail["description"] }}
              >
                {/* <ul className="list-disc list-outside">
                  {detail["description"]?.split("\n").map((str) => (
                    <li>{str}</li>
                  ))}
                </ul> */}
              </div>
            </div>
            <div>
              {canRedeem && (
                <PrimaryButton
                  variant="contained"
                  className="w-full"
                  disabled={+detail["points"] <= 0}
                  onClick={postCreateUsePoint}
                >
                  ใช้ {detail["points"]?.toLocaleString()} คะแนน
                </PrimaryButton>
              )}
              {!canRedeem && (
                <PrimaryButton variant="contained" className="w-full" disabled>
                  { isEmpty ? 'ขออภัย ขณะนี้สินค้าหมดแล้ว' : 'คะแนนของคุณไม่เพียงพอ'}
                </PrimaryButton>
              )}
              <div className="my-3"></div>
              <SecondaryButton
                variant="outlined"
                className="w-full"
                onClick={() => {
                  navigate(-1);
                }}
              >
                ยกเลิก
              </SecondaryButton>
              <div className="my-3"></div>
            </div>
          </div>
        </div>
      )}
      {usePointError && <RedeemGiftError onClick={onClickBackFromError} />}
      {!usePointError && successData && (
        <RedeemGiftSuccess detail={successData} />
      )}
    </>
  );
}
