import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import Divider from '@mui/material/Divider';
import BottomNavigation from '@mui/material/BottomNavigation';
import Paper from '@mui/material/Paper';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { styled } from '@mui/material/styles';

import LogoHeader from "../../assets/images/logo-header.png";
import correct from "../../assets/images/correct.png";
import axios from "axios";
import dayjs from "dayjs";
import CustomPopup from "../../components/popup/CustomPopup";

const ShowRouteButton = styled(Button, {
    shouldForwardProp: (prop) => prop !== "showLabel"
})({
    fontFamily: 'inherit',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '21px',
    height: '48px',
    borderRadius: '28px',
    backgroundColor: '#0A4197',
    color: 'white',
    minWidth: '100px',
    gap: '10px',
    justifyContent: 'center',
    alignItems: 'center',
    flex: '1',
    display: 'flex',
    padding: '15px',
})

const BackButton = styled(Button, {
    shouldForwardProp: (prop) => prop !== "showLabel"
})({
    fontFamily: 'inherit',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '24px',
    height: '48px',
    borderRadius: '28px',
    backgroundColor: 'white',
    color: '#0A4197',
    minWidth: '100px',
    gap: '10px',
    justifyContent: 'center',
    alignItems: 'center',
    flex: '1',
    display: 'flex',
    padding: '15px',
})

export default function SpectraNearYouCoupons(props) {
    const [open, setOpen] = React.useState(false);
    const [openSelect, setOpenSelect] = React.useState(false);
    const [dog, setDog] = React.useState();
    const [dogList, setDogList] = React.useState([]);
    const [coupon, setCoupon] = React.useState();
    const [openErrorPopup, setOpenErrorPopup] = React.useState(false);
    const [errorTxt, setErrorTxt] = React.useState('');
    const [successData, setSuccessData] = React.useState();

    const memberId = useLocation()?.state?.memberId;
    const campaignId = useLocation()?.state?.campaignId;
    const shopName = useLocation()?.state?.shopName;
    const clinicPetShopId = useLocation()?.state?.clinicPetShopId;

    const navigate = useNavigate();

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleOpenSelect = () => setOpenSelect(true);
    const handleCloseSelect = () => setOpenSelect(false);

    const handleChange = (event) => {
        setDog(event.target.value);
    };

    const handleClick = () => {
        props.func(true);
    }

    const goBack = () =>
        navigate(-1);

    React.useEffect(() => {
        console.log('memberId >>' + memberId)
        console.log('campaignId >>' + campaignId)
        getSearchCouponById()
        getSearchPet()
    }, []);

    async function getSearchCouponById() {
        const baseUrl = process.env.REACT_APP_BASE_URL || 'https://api-ngs.scapp.work'
        try {
            const { data } = await axios.get(baseUrl + '/searchCouponById', {
                params: {
                    memberId: memberId,
                    campaignId: campaignId,
                }
            });
            console.log(data.body);
            setCoupon(data.body)
        } catch (err) {
            console.error(err);
        }
    }

    async function getSearchPet() {
        const baseUrl = process.env.REACT_APP_BASE_URL || 'https://api-ngs.scapp.work'
        try {
            const { data } = await axios.get(baseUrl + '/searchPet', {
                params: {
                    memberId: memberId,
                }
            });
            setDogList(data.body)
        } catch (err) {
            console.error(err);
        }
    }

    async function postCreateHistoryCoupon() {
        const baseUrl = process.env.REACT_APP_BASE_URL || 'https://api-ngs.scapp.work'
        try {
            const { data } = await axios.post(baseUrl + '/createHistoryCoupon', {
                memberId: memberId,
                clinicPetShopId: clinicPetShopId,
                campaignId: campaignId,
                petId: dog
            });
            console.log("resData.data >>", data);
            if (data.resultCode === 20000) {
                setSuccessData(data.body)
                setOpenSelect(true)
                console.log("resData.data >>", data.body);
            } else {
                setErrorTxt(data.resultDescription)
                setOpenErrorPopup(true)
            }

        } catch (err) {
            console.error(err);
        }
    }




    return (
        <div
            style={{
                background:
                    "linear-gradient(152.04deg, rgb(10, 65, 151) -0.97%, rgb(32, 159, 237) 81.57%)",
                minHeight: "calc(100vh - 60px)",
                padding: "20px",
            }}
        >
            <CustomPopup
                title={"เกิดข้อผิดพลาด"}
                desc={errorTxt}
                cancelText={"ปิด"}
                actionText={"ย้อนกลับ"}
                actionFunction={() => {
                    navigate(-1);
                    setOpenErrorPopup(false);
                }}
                open={openErrorPopup}
            />
            <Card sx={{ minWidth: 275, marginBottom: '15px' }}>
                <CardContent>
                    <Typography sx={{
                        fontFamily: `'DB Helvethaica X', 'Kanit', sans-serif`,
                        fontSize: 19,
                        fontWeight: '550',
                        textAlign: 'center',
                    }} color="red"
                        component={'div'}
                    >
                        ใช้คูปองที่หน้าร้านเท่านั้น
                    </Typography>

                    <Typography sx={{
                        fontFamily: `'DB Helvethaica X', 'Kanit', sans-serif`,
                        fontSize: 25,
                        fontWeight: '500',
                        textAlign: 'center',
                    }} color="#29A3EE"
                        component={'div'}
                    >
                        {shopName}
                    </Typography>

                    <Typography sx={{
                        fontFamily: `'DB Helvethaica X', 'Kanit', sans-serif`,
                        fontSize: 30,
                        textAlign: 'center',
                    }} color="black"
                        component={'div'}
                    >
                        {coupon?.name}
                    </Typography>

                    <Typography sx={{
                        fontFamily: `'DB Helvethaica X', 'Kanit', sans-serif`,
                        fontSize: 20,
                        textAlign: 'center',
                        paddingBottom: '15px',
                    }} color="#808080"
                        component={'div'}
                    >
                        ใช้คูปองได้ถึง {coupon ? dayjs(coupon.expire).format("DD/MM/YYYY") : ''}
                    </Typography>

                    <Divider light />

                    <Typography sx={{
                        fontFamily: `'DB Helvethaica X', 'Kanit', sans-serif`,
                        fontSize: 25,
                        paddingTop: '15px',
                    }} color="#29A3EE"
                        component={'div'}
                    >
                        เงื่อนไขการใช้คูปอง
                    </Typography>

                    <Typography sx={{
                        fontFamily: `'DB Helvethaica X', 'Kanit', sans-serif`,
                        fontSize: 19,
                        paddingTop: '15px',
                        paddingLeft: '10px',
                    }} color="#393939"
                        component={'div'}
                    >

                        <ul style={{
                            listStyleType: 'disc',
                            paddingLeft: '15px',
                        }}>
                            <li>คูปองไม่สามารถนำกลับมาใช้ได้ เมื่อเวลาที่แสดงหมดลง</li>
                            <li>เมื่อท่านกดใช้ต้องแสดงรหัส Unique Code กับพนักงานภายใน 10 นาที</li>
                            <li>คูปองไม่สามารถนำกลับมาใช้ได้ เมื่อเวลาที่แสดงหมดลง</li>
                        </ul>
                    </Typography>

                    <Typography sx={{ mb: 1.5 }} color="text.secondary" component={'div'}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                                <ShowRouteButton
                                    variant="contained"
                                    style={{
                                        fontSize: '22px',
                                        marginTop: '50px',
                                    }}
                                    onClick={handleOpen}
                                >
                                    ต่อไป
                                </ShowRouteButton>
                            </Grid>
                            <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                                <ShowRouteButton
                                    variant="contained"
                                    style={{
                                        fontSize: '22px',
                                        marginTop: '5px',
                                        backgroundColor: 'white',
                                        color: '#0A4197',
                                        border: '1px solid #0A4197',
                                    }}
                                    onClick={goBack}
                                >
                                    ย้อนกลับ
                                </ShowRouteButton>
                            </Grid>
                        </Grid>
                    </Typography>
                </CardContent>
            </Card>

            <div>
                <Modal
                    open={open}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <React.Fragment>
                        <div className="flex justify-center text-center top-logo">
                            <img src={LogoHeader} alt="logo" className="w-[60px]" />
                        </div>
                        <div
                            style={{
                                background:
                                    "linear-gradient(152.04deg, rgb(10, 65, 151) -0.97%, rgb(32, 159, 237) 81.57%)",
                                minHeight: "calc(100vh)",
                                padding: '50px 10px 10px 10px'
                            }}
                        >
                            <Card sx={{ minWidth: 275, marginBottom: '15px' }}>
                                <CardContent>
                                    <Typography sx={{
                                        fontFamily: `'DB Helvethaica X', 'Kanit', sans-serif`,
                                        fontSize: 26,
                                        textAlign: 'center',
                                    }} color="#29A3EE"
                                        component={'div'}
                                    >
                                        เลือกน้องหมาที่ต้องการใช้คูปอง
                                    </Typography>

                                    <div style={{
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        display: 'flex',
                                    }}>
                                        <FormControl required variant="standard" sx={{ m: 1, minWidth: '100%' }}>
                                            <InputLabel id="demo-simple-select-standard-label">เลือกน้องหมา</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                value={dog}
                                                onChange={handleChange}
                                                label="dog"
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                {
                                                    dogList.map((item, i) => (
                                                        <MenuItem value={item.petId} key={i}>
                                                            {item.petName}
                                                        </MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </FormControl>
                                    </div>

                                    <Typography sx={{
                                        fontFamily: `'DB Helvethaica X', 'Kanit', sans-serif`,
                                        fontSize: 17,
                                        textAlign: 'center',
                                        lineHeight: '1.25',
                                    }} color="red"
                                        component={'div'}
                                    >
                                        <p>กรุณากดยืนยันเมื่ออยู่ที่หน้าร้านค้าแล้วเท่านั้น</p>
                                        <p>{`หากกด'ยืนยัน'ไม่สามารถแก้ไขเปลี่ยนแปลงข้อมูลได้`}</p>
                                    </Typography>

                                    <Typography sx={{ mb: 1.5 }} color="text.secondary" component={'div'}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                                                <ShowRouteButton
                                                    variant="contained"
                                                    style={{
                                                        fontSize: '22px',
                                                        marginTop: '20px',
                                                    }}
                                                    onClick={postCreateHistoryCoupon}
                                                >
                                                    ยืนยัน
                                                </ShowRouteButton>
                                            </Grid>
                                        </Grid>
                                    </Typography>
                                </CardContent>
                            </Card>

                            <Paper sx={{
                                position: 'fixed', bottom: 0, left: 0, right: 0
                            }} elevation={1}>
                                <BottomNavigation
                                    style={{
                                        alignItems: 'center', height: '90px'
                                    }}
                                >
                                    <BackButton
                                        variant="outlined"
                                        style={{
                                            minWidth: "90px",
                                            margin: "0px 15px",
                                        }}
                                        onClick={handleClose}
                                    >
                                        ย้อนกลับ
                                    </BackButton>
                                </BottomNavigation>
                            </Paper>
                        </div>
                    </React.Fragment>
                </Modal>
            </div>

            <div>
                <Modal
                    open={openSelect}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <React.Fragment>
                        <div className="flex justify-center text-center top-logo">
                            <img src={LogoHeader} alt="logo" className="w-[60px]" />
                        </div>
                        <div
                            style={{
                                background:
                                    "linear-gradient(152.04deg, rgb(10, 65, 151) -0.97%, rgb(32, 159, 237) 81.57%)",
                                minHeight: "calc(100vh)",
                                padding: '50px 30px'
                            }}
                        >

                            <Typography sx={{
                                fontFamily: `'DB Helvethaica X', 'Kanit', sans-serif`,
                                fontSize: 35,
                                justifyContent: 'center',
                                display: 'flex',
                                lineHeight: '1.25',
                            }} color="white"
                                component={'div'}
                            >
                                <img src={correct} alt="checkmark" />
                            </Typography>

                            <Typography sx={{
                                fontFamily: `'DB Helvethaica X', 'Kanit', sans-serif`,
                                fontSize: 35,
                                textAlign: 'center',
                                lineHeight: '1.25',
                            }} color="white"
                                component={'div'}
                            >
                                ใช้คูปองสำเร็จ
                            </Typography>

                            <Typography sx={{
                                fontFamily: `'DB Helvethaica X', 'Kanit', sans-serif`,
                                fontSize: 25,
                                textAlign: 'center',
                                lineHeight: '1.25',
                                paddingBottom: '40px'
                            }} color="white"
                                component={'div'}
                            >
                                 {coupon?.name}
                            </Typography>

                            <Typography sx={{
                                fontFamily: `'DB Helvethaica X', 'Kanit', sans-serif`,
                                fontSize: 20,
                                lineHeight: '1.25',
                                paddingBottom: '10px'
                            }} color="white"
                                component={'div'}
                            >
                                รายละเอียด
                            </Typography>

                            <Divider light />

                            <Typography sx={{ mt: 1.5 }} color="white" component={'div'}>
                                <Grid container spacing={2} >
                                    <Grid item xs={3} display="flex" justifyContent="left">
                                        วันที่/เวลา:
                                    </Grid>
                                    <Grid item xs={9} display="flex" justifyContent="end">
                                       {successData ? dayjs(successData.createdDate).format("DD/MM/YYYY") : '-'}
                                    </Grid>
                                    <Grid item xs={3} display="flex" justifyContent="left"
                                        style={{ paddingTop: 0 }}>
                                        ร้านค้า
                                    </Grid>
                                    <Grid item xs={9} display="flex" justifyContent="end"
                                        style={{ paddingTop: 0 }}>
                                       {successData?.clinicPetShop.shopNo} {successData?.clinicPetShop.shopName}
                                    </Grid>
                                    <Grid item xs={3} display="flex" justifyContent="left"
                                        style={{ paddingTop: 0 }}>
                                        รหัสอ้างอิง
                                    </Grid>
                                    <Grid item xs={9} display="flex" justifyContent="end"
                                        style={{ paddingTop: 0 }}>
                                        {successData?.code}
                                    </Grid>
                                </Grid>
                            </Typography>

                            <Typography sx={{ mb: 1.5 }} color="text.secondary" component={'div'}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                                        <ShowRouteButton
                                            variant="contained"
                                            style={{
                                                fontSize: '22px',
                                                marginTop: '20px',
                                                position: 'absolute',
                                                bottom: 0,
                                                margin: '40px',
                                                width: '80%',
                                                backgroundColor: '#1A84CD',
                                            }}
                                            onClick={goBack}
                                        >
                                            เสร็จสิ้น
                                        </ShowRouteButton>
                                    </Grid>
                                </Grid>
                            </Typography>
                        </div>
                    </React.Fragment>
                </Modal>
            </div>
        </div>
    )
}